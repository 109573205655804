@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  overflow: hidden;
  height: 100%;
}

body {
  overflow: auto;
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  font-family: 'Outfit';
  font-weight: 400;
  background: linear-gradient(109deg, #15181f 4.29%, #13161d 99.55%);
  color: #ffffff;
  overflow-x: hidden;
}

button {
  font-family: 'Outfit' !important;
  text-transform: capitalize !important;
}

a {
  color: #7ec6ef;
  text-decoration: none;
}

a:hover {
  color: #7ec6ef;
  text-decoration: none;
}

/* Custom Scrollbar */
*::-webkit-scrollbar {
  width: 6px;
  height: 5px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

*::-webkit-scrollbar-thumb {
  background-color: #7ec6ef;
}

img.brand_logo {
  width: 130px;
}
.collapse{
  visibility: inherit !important;
}
.navbar-sticky--moved-up {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #0e1116 !important;
  margin-top: -100px;
  -webkit-box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
  box-shadow: 0 0.1875rem 0.9375rem 0 rgba(0, 0, 0, 0.15);
}

.navbar-sticky--on {
  margin-top: 0;
}

.navbar-sticky--transitioned {
  -webkit-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
}

/* Dashboard Css */
.apppage_wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.app_page {
  display: flex;
  flex: 1 0 auto;
  flex-direction: column;
}

.app_header {
  z-index: 100;
  position: fixed;
  left: 200px;
  right: 0;
  top: 0;
  background: transparent;
  transition: none;
  display: flex;
  align-items: stretch;
  border-bottom: 1px solid #000;
}

.app_header .offcanvas-body,
.app_header .offcanvas-body .navbar-nav {
  align-items: center;
  gap: 16px;
}

.notification_link {
  position: relative;
}

.notification_link span {
  width: 12px;
  height: 12px;
  flex-shrink: 0;
  border-radius: 12px;
  border: 2px solid #1d1e25;
  background: #ff5555;
  display: inline-block;
  position: absolute;
  right: 8px;
  top: 5px;
}

.search_input_div {
  height: 52px;
}

.search_input_div .input-group-text {
  border-radius: 10px;
  background: rgba(26, 30, 39, 0.5);
  border: 1px solid rgba(26, 30, 39, 0.5);
  border-right: none;
}

.search_input_div .form-control {
  border-radius: 10px;
  background: rgba(26, 30, 39, 0.5);
  border: 1px solid rgba(26, 30, 39, 0.5);
  box-shadow: none !important;
  color: #fff;
  font-size: 18px;
  border-left: none;
}

.search_input_div .form-control::placeholder {
  color: rgba(60, 60, 67, 0.6);
}
.search_input_div_chg .form-control,
.search_input_div_chg .input-group-text{
  background: rgb(39 44 56 / 61%);
}
button.navbar-toggler {
  box-shadow: none !important;
}

.offcanvas-header .btn-close {
  filter: invert(1);
  box-shadow: none !important;
}

.app_sidebar {
  background: #0e1116;
  border-right: 0;
  width: 220px;
  position: fixed;
  z-index: 105;
  top: 0;
  bottom: 0;
  left: 0;
  transition: width 0.3s ease;
  height: 100%;
}

.page_wrapper {
  margin-left: 220px;
  margin-top: 120px;
  transition:
    margin-left 0.3s ease,
    margin-right 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
}

.page_wrapper_nonauth {
  margin-left: 0px;
  margin-top: 0px;
  transition:
    margin-left 0.3s ease,
    margin-right 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
}

.page_content {
  transition: margin 0.3s ease;
  display: flex;
  flex: 1 auto;
  min-width: 0;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
  z-index: 1;
}

.bg_top::before {
  content: '';
  background: url('./assets/images/top_bg.png') no-repeat top;
  background-size: cover;
  width: 100%;
  height: 233px;
  position: absolute;
  top: -120px;
  z-index: -1;
}

.app_sidebar_menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: calc(100% - 180px);
}

.app_sidebar_logo {
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}

ul.sidebar_menu {
  padding-left: 0;
}

ul.sidebar_menu li+li {
  margin-top: 22px;
}

ul.sidebar_menu li a.nav-link {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.8px;
  text-decoration: none;
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 0 !important;
  position: relative;
  background: transparent;
  box-shadow: none;
  transition: 0.2s all;
}

ul.sidebar_menu li a.nav-link.active,
ul.sidebar_menu li a.nav-link:hover {
  background: #7ec6ef;
  box-shadow: 0px 0px 8px 0px rgba(122, 215, 255, 0.45);
  color: #0e1116;
}

ul.sidebar_menu li a.nav-link.active {
  font-weight: 700;
}

ul.sidebar_menu li img {
  margin-right: 10px;
}

ul.sidebar_menu li a.nav-link.active img,
ul.sidebar_menu li a.nav-link:hover img {
  filter: brightness(0);
}

ul.sidebar_menu li a.nav-link.active svg path,
ul.sidebar_menu li a.nav-link:hover svg path {
  stroke: #0e1116;
}

ul.sidebar_menu li a.nav-link.btn-toggle::after {
  width: 1.25em;
  line-height: 0;
  content: url('../src/assets/images/menu_caret_icon.svg');
  transition: transform 0.35s ease;
  transform-origin: 0.5em 50%;
  position: absolute;
  right: 10px;
  filter: invert(1);
}

ul.sidebar_menu li a.nav-link.btn-toggle[aria-expanded='true']::after {
  transform: rotate(90deg);
}

.sidebar_submenu {
  padding-left: 54px;
  margin-top: 12px;
}

.sidebar_submenu ul li a {
  position: relative;
  font-size: 14px;
  color: #fff;
  transition: 0.2s all;
}

.sidebar_submenu ul li a:hover {
  color: #f3ba2f;
}

.sidebar_submenu ul li a::before {
  content: '';
  background: #fff;
  width: 5px;
  height: 5px;
  position: absolute;
  border-radius: 50%;
  top: 7px;
  left: -15px;
}

h2.page_title {
  color: #fff;
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 2.4px;
  margin-bottom: 0;
  text-transform: capitalize;
}

.usericon_dropdown span {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.8px;
  margin-left: 20px;
}

.topnavbar_right .dropdown-toggle::after {
  content: '';
  background: url('../src/assets/images/caret_icon.svg') no-repeat;
  border: 0;
  width: 15px;
  height: 10px;
  vertical-align: 0;
  margin-left: 15px;
}

.usericon_dropdown .dropdown-menu {
  background: #1e232e;
  border: 1px solid #0e1116;
  /* display: block;
  opacity: 0; */
  top: 100% !important;
  transform: translateY(20px) !important;
  transition: all 0.5s;
  min-width: 200px;
  right: 0px;
  left: unset;
}

.usericon_dropdown .dropdown-menu-dark.show {
  transform: translateY(0) !important;
  /* opacity: 1; */
}

.usericon_dropdown .dropdown-menu-dark .dropdown-item.active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:active,
.usericon_dropdown .dropdown-menu-dark .dropdown-item:hover {
  background-color: #111111;
}

.usericon_dropdown .dropdown-menu-dark .dropdown-item {
  border-bottom: 1px solid #0e1116;
}

.usericon_dropdown .dropdown-menu-dark li:last-child .dropdown-item {
  border-bottom: none;
}

h2.dash_inner_title {
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-transform: capitalize;
  margin-bottom: 0;
}

h2.dash_inner_title_small {
  font-size: 14px;
  line-height: normal;
}

.red_text {
  color: #e34a4a !important;
}

.green_text {
  color: #5be38a !important;
}

.blue_text {
  color: #87d4f6 !important;
}

.dashbox {
  border-radius: 10px;
  border: 1px solid #1e232e;
  background: rgba(14, 17, 22, 0.9);
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  padding: 30px;
  margin-bottom: 24px;
}

.dashbox_small_pad {
  padding: 20px;
}

.link_box_panel {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 24px;
}

.link_box {
  border-radius: 10px;
  border: 1px solid #1e232e;
  background: #0e1116;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 12px;
  padding: 20px;
  color: #fff;
  height: 100%;
  cursor: pointer;
}

.link_box h2 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
}

.dashbox_fullheight {
  height: 100%;
}

.dash_inner_subtxt {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}

.rdt_Table {
  background: transparent !important;
}

.rdt_TableHeadRow {
  background: transparent !important;
}

.rdt_TableRow {
  margin-top: 8px;
  border-bottom: none !important;
  border-radius: 10px;
  height: 70px;
}

.rdt_TableCol {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  justify-content: center;
}

.rdt_TableCell {
  color: #fff !important;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  justify-content: center;
}

.rdt_Pagination {
  margin-top: 8px;
}

.rdt_ExpanderRow {
  border-radius: 10px;
  margin-top: 8px;
}

.sale_order_bg {
  background: rgba(26, 160, 83, 0.36);
  display: inline-block;
  padding: 3px 5px;
}

.align_right {
  text-align: right;
  width: 100%;
}

.dashtitle_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.dashtitle_flex a {
  font-size: 14px;
}

.fa-rotate-45 {
  transform: rotate(45deg);
}

.total_fund_value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
}

.total_fund_value h5 {
  color: rgba(255, 255, 255, 1);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  margin: 0 0 5px;
}

.total_fund_value h3 {
  color: #87d4f6;
  font-size: 20px;
  font-weight: 800;
  line-height: normal;
  margin: 0;
}

.total_fund_value i {
  color: #636b7c;
}

.traffic_chart_flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.asset_chart_info {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.asset_chart_info h2 {
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 24px;
}

.asset_chart_info ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.asset_chart_info ul li {
  position: relative;
  padding-left: 30px;
  margin-bottom: 16px;
}

.asset_chart_info ul li::before {
  content: '';
  background: #83fae3;
  width: 20px;
  height: 20px;
  position: absolute;
  top: 10px;
  left: 0;
  border-radius: 100px;
}

.asset_chart_info ul li:nth-child(2):before {
  background: #fee083;
}

.asset_chart_info ul li:nth-child(3):before {
  background: #fe70b8;
}

.asset_chart_info ul li:nth-child(4):before {
  background: #7ef37d;
}

.asset_chart_info ul li:nth-child(5):before {
  background: #a67df3;
}

.asset_chart_info ul li:nth-child(6):before {
  background: #ffc14d;
}

.asset_chart_info ul li:nth-child(7):before {
  background: #ff2e51;
}

.asset_chart_info ul li p {
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 0;
}

.asset_chart_info ul li p span {
  font-weight: 700;
}

.asset_chart_info ul li label {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}

button.border_btn {
  border-radius: 8px;
  border: 1px solid #80c8f0;
  background: #0e1116;
  color: #80c8f0;
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
  text-transform: capitalize;
  width: 100%;
  padding: 12px 20px;
}

.btn-group_custom {
  background: #14171e;
  border-radius: 8px;
}

.btn-group_custom .grp_btn {
  background: #14171e;
  border: 1px solid #14171e;
  font-size: 14px;
  color: #fff;
  font-weight: 400;
  line-height: 22px;
  border-radius: 8px;
  padding: 2px 10px;
}

.btn-group_custom .grp_btn.active {
  background: #7ec6ef;
  color: #0e1116;
}

.hrs_chart_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  height: 100%;
}

.view_all_link {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}

.recent_activity_panel {
  margin-top: 24px;
}

.recent_activity_div {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  border-radius: 10px;
  background: #13181f;
  padding: 16px;
  margin-bottom: 18px;
  margin-right: 15px;
}

.activity_dot {
  background: #87d4f6;
  width: 10px;
  height: 10px;
  border-radius: 100px;
  margin-top: 4px;
}

.activity_content {
  display: flex;
  flex-direction: column;
  gap: 7px;
  align-self: stretch;
  width: 100%;
}

.activity_content h2 {
  color: #87d4f6;
  font-size: 13px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 0.464px;
  margin-bottom: 0;
}

.activity_content h5 {
  color: #e3eaef;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  margin-bottom: 0;
}

.activity_content p {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: 24px;
  text-align: right;
  margin-bottom: 0;
}

.trans_status {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.trans_success .activity_dot {
  background: #5be38a;
}

.trans_success .activity_content h2, .trans_success .activity_content h2 a {
  color: #5be38a;
}

.trans_failed .activity_dot {
  background: #e34a4a;
}

.trans_failed .activity_content h2, .trans_failed .activity_content h2 a {
  color: #e34a4a;
}

.custom_datepicker .react-datepicker-wrapper {
  position: relative;
  z-index: 1;
  width: 100%;
}

.custom_datepicker .react-datepicker__input-container input {
  border: 1px solid rgba(122, 183, 244, 0.25);
  border-radius: 5px;
  background: #1a1e27;
  padding: 13px 15px;
  font-size: 14px;
  font-style: normal;
  font-weight: 100;
  line-height: normal;
  color: #fff;
  outline: none !important;
  width: 100%;
}

/* .custom_datepicker .react-datepicker-wrapper::before {
  content: "";
  background: url("../src/assets/images/calendar_icon.png") no-repeat right
    center;
  width: 14px;
  height: 14px;
  position: absolute;
  right: 10px;
  top: 8px;
  z-index: -1;
} */
.react-datepicker {
  background-color: #1a1e27 !important;
  color: #fff !important;
  border: 1px solid #1a1e27 !important;
  font-family: 'Outfit' !important;
}

.react-datepicker__header {
  background-color: #1a1e27 !important;
  border-bottom: 1px solid rgba(122, 183, 244, 0.25) !important;
  color: #fff !important;
}

.react-datepicker__time-container .react-datepicker__time {
  background-color: #1a1e27 !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header,
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #fff !important;
}

.react-datepicker__time-container {
  border-left: 1px solid rgba(122, 183, 244, 0.25) !important;
}

.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after,
.react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
  border-bottom-color: #1a1e27 !important;
}

.react-datepicker__navigation {
  top: 8px !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  background-color: #161519 !important;
  color: #fff !important;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  background-color: #151417 !important;
  color: #ffffff !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  background-color: #161519 !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #7ec6ef !important;
  color: #111 !important;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #7ec6ef !important;
  color: #111 !important;
}

.topnavbar_right {
  display: flex;
  align-items: center;
  gap: 30px;
}

.topnavbar_right i.fa-bars,
.app_sidebar i.close_icon {
  display: none;
}

.page_content .container-fluid,
.app_header .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.admin_profile img {
  width: 32px;
  height: 32px;
  margin-right: 10px;
}

.top_filter_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
}

.top_filter {
  display: flex;
  align-items: center;
  gap: 15px;
}

.MuiCheckbox-root {
  color: #f3ba2f !important;
}

.auth_header ul.navbar-nav {
  gap: 16px;
}

.auth_header ul li a {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.auth_header ul li a:hover {
  color: #7ec6ef;
}

/* .iWyFZZ div:first-child {
  white-space: pre-line !important;
} */

/* Login */
.auth_wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.auth_main {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 10px;
  background: linear-gradient(100deg, rgba(14, 17, 22, 0.6) 9.14%, rgba(17, 20, 26, 0.6) 75.07%);
  width: 100%;
  min-height: 80vh;
}

nav.navbar.navbar-expand-xl.navbar-dark.auth_header {
  z-index: 100;
}

.auth_wrapper::before {
  content: '';
  background: url('./assets/images/wheel.png') no-repeat center left;
  width: 666px;
  height: 100%;
  position: absolute;
  right: 0;
  mix-blend-mode: lighten;
}

.auth_left {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px;
}

.auth_left h2 {
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.auth_left h2 span {
  font-weight: 700;
}

.auth_left label {
  color: #fff;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 10px;
}

.register_here {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
  margin-top: 24px;
}

.register_here a {
  color: #7ec6ef;
  text-decoration-line: underline;
}

.auth_form input.form-control {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: #1a1e27 !important;
  backdrop-filter: blur(2.5px);
  padding: 18px 20px;
  color: #fff;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
}

.auth_form select.select-control {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: #1a1e27 !important;
  padding: 18px 20px;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  color: #fff;
}

.auth_form input.form-control::placeholder {
  color: #7ec6ef;
}

.auth_footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.auth_footer label {
  margin-bottom: 0;
}

.auth_footer a {
  color: #7ec6ef;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.custom_formcheck .form-check-input {
  border-radius: 2px;
  border: 1px solid rgba(122, 183, 244, 0.25);
  background: rgba(0, 184, 129, 0.05);
  box-shadow: none;
}

.auth_form .form-group {
  margin-bottom: 20px;
}

.auth_main_forgot {
  background: #000;
  padding: 70px;
  justify-content: center;
}

.auth_main_forgot .auth_left {
  width: 50%;
}

.auth_main_forgot .auth_left img {
  width: 150px;
  margin-bottom: 40px;
}

.auth_main_forgot .auth_left h2 {
  font-size: 32px;
}

.auth_left button {
  border-radius: 5px;
  padding: 12px 30px;
  color: #11151a;
  font-family: 'Outfit';
  font-size: 16px;
  font-weight: 700;
  line-height: normal;
  width: 100%;
  text-transform: capitalize;
}

.auth_left button a {
  color: #11151a !important;
}

.google_auth_inp_group {
  border-radius: 4px;
  background: #161616 !important;
  border: 1px solid #161616 !important;
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.google_auth_inp_group input.form-control {
  width: 10%;
  border-bottom: 2px solid #fff !important;
  border-radius: 0;
  color: #fff;
  text-align: center;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.otp_wrap {
  display: flex;
  gap: 10px;
}

.otp_wrap input {
  text-align: center;
}

.dashbox_fullheight_row [class*='col-'] {
  margin-bottom: 24px;
}

.wallet_panel {
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  margin-bottom: 24px;
}

.wallet_asset_div {
  display: flex;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 10px;
  border: 1px solid #1e232e;
  background: #0e1116;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
}

.wallet_asset_div .asset_btn_grp {
  flex-wrap: wrap;
}

.asset_btn_grp {
  display: flex;
  gap: 8px;
}

.asset_btn_grp button.border_btn {
  white-space: nowrap;
  padding: 10px 12px;
}

.wallet_asset_div h2 {
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  margin: 0;
}

.wallet_asset_div h5 {
  color: rgba(255, 255, 255, 0.5);
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
  margin: 0;
}

.primary_modal .modal-content {
  background: transparent;
  border: none;
}

.primary_modal .modal-header .btn-close {
  position: absolute;
  right: 20px;
  filter: invert(1);
  top: 20px;
  box-shadow: none !important;
}

.primary_modal .modal-header {
  border-radius: 32px 32px 0px 0px;
  border-bottom: none;
  background: #1a1e27;
  padding: 20px 0;
}

.primary_modal .modal-body {
  background: #1a1e27;
  padding-left: 30px;
  padding-right: 30px;
}

.primary_modal .modal-footer {
  border-top: none;
  justify-content: space-around;
  flex-wrap: nowrap;
  padding: 24px 0 0 0;
  gap: 10px;
}

.primary_modal .modal-footer button {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  padding: 12px 20px;
  border-radius: 5px;
}

button.primary_btn {
  color: #11151a;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
  padding: 12px 20px;
  border-radius: 5px;
}

.modal-backdrop {
  background: rgba(14, 17, 22, 0.8);
  backdrop-filter: blur(4.5px);
  opacity: 0.95 !important;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.primary_input {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: #1a1e27 !important;
  padding: 12px 15px;
  box-shadow: none !important;
  color: #a1a3a8 !important;
  font-size: 14px;
  font-weight: 100;
}

.primary_select {
  border-radius: 5px;
  border: 1px solid rgba(122, 183, 244, 0.25) !important;
  background: #1a1e27 url('./assets/images/select_dropdown.png') no-repeat right 0.75rem center;
  background-size: 13px 8px !important;
  color: #fff;
  padding: 12px 15px;
  box-shadow: none !important;
  font-size: 14px;
  font-weight: 100;
}

.primary_select::placeholder,
.primary_input::placeholder {
  color: rgba(255, 255, 255, 0.5);
}

.selected_bankdetails {
  text-align: center;
  margin-top: 30px;
}

.selected_bankdetails h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.selected_bankdetails h5 span {
  display: block;
}

.primary_modal .modal-footer button.border_btn {
  border: 1px solid #7ec6ef;
  color: #7ec6ef;
  background: transparent;
}

.quote_no {
  text-align: center;
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}

.quote_no span {
  color: #fff6ea;
  font-weight: 400;
}

.sendquote_div {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}

.sendquote_div_left h2 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  margin-top: 16px;
}

.sendquote_div_left h5 {
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}

.sendquote_div_left label {
  display: block;
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 10px 0;
  border-bottom: 1px solid #15181f;
}

.sendquote_div_left label span {
  color: #fff6ea;
  font-weight: 400;
}

.sendquote_div_left,
.sendquote_div_right {
  width: 50%;
}

.sendquote_div_right {
  text-align: center;
}

.qr_address {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  margin: 20px 0;
}

.qr_address p {
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 0;
}

.qr_address img {
  cursor: pointer;
}

.sendquote_div_right h3 {
  color: rgba(255, 246, 234, 0.5);
  font-size: 12px;
  font-weight: 300;
  line-height: normal;
}

.sendquote_div_right h3 span {
  color: #fff6ea;
  font-weight: 400;
}

.table_filter_options {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.dashbox_dark {
  background: #0e1116;
  border: 1px solid #0e1116;
  box-shadow: none;
}

.table_filter_options .btn-group_custom .grp_btn {
  padding: 6px 20px;
}

button.filter_btn {
  padding: 12px;
  border-radius: 10px;
  background: #14171e;
  min-width: unset;
}

.table_filter_options .search_input_div {
  height: 38px;
}

.table_expand_data {
  padding: 32px 32px 24px 32px;
  border-radius: 10px;
  border: 1px solid #7ec6ef;
  background: #0e1116;
  box-shadow: 0px 0px 12px 0px rgba(128, 200, 240, 0.5);
  width: 550px;
  margin: 30px auto;
}

.table_expand_data h2 {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}

.table_expand_data_wrap {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
}

.table_expand_data label {
  color: rgba(255, 246, 234, 0.5);
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 20px;
}

.table_expand_data label span {
  font-weight: 400;
  color: #fff6ea;
}

.table_expand_data_button {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.table_expand_data_button button {
  width: auto;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  border-radius: 8px;
  padding: 8px 24px;
}

.profile_img {
  width: 150px;
  height: 150px;
  border-radius: 10px;
  border: 1px solid #1e232e;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  margin-top: -50px;
}

.profile_top {
  display: flex;
  align-items: center;
  gap: 30px;
}

.profile_data p {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 8px;
}

.profile_top>div h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
  margin: 10px 0 0;
}

.profile_data {
  margin-top: -70px;
}

.dashbox_nopad {
  padding: 0;
}

.account_details_panel {
  display: flex;
  width: 100%;
  gap: 15px;
}

.account_details_left {
  padding: 24px 0px;
  border-radius: 8px;
  border-right: 1px solid #1e232e;
  min-width: 180px;
}

.account_details_right {
  padding: 24px;
  width: 100%;
  min-height: 80vh;
}

.account_details_box {
  padding: 24px;
  border-radius: 8px;
  border: 1px solid #1e232e;
  position: relative;
  margin-bottom: 24px;
}

.custom_vertical_tab.nav-pills .nav-link {
  color: #fff;
  border-radius: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  padding: 8px 24px;
  text-align: left;
}

.custom_vertical_tab.nav-pills .nav-link.active {
  background: #1e232e;
  font-weight: 700;
}

.profile_inp_row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.profile_inp_row label {
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  white-space: nowrap;
}

.profile_inp {
  border-radius: 5px;
  border: 1px solid rgba(126, 198, 239, 0.25) !important;
  background: #1a1e27 !important;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  color: #fff;
  padding: 5px;
  width: 100%;
}

.profile_inp:disabled {
  border-color: transparent !important;
  background: transparent !important;
}

.edit_icon {
  cursor: pointer;
}

.security_list {
  list-style: none;
  padding-left: 30px;
  margin: 24px 0 0;
}

.security_list li {
  margin-bottom: 10px;
  transition: 0.2s all;
  display: flex;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
}

.security_list li button {
  color: #fff;
  background: transparent !important;
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  padding: 0;
}

.security_list li button:hover {
  text-decoration-line: underline;
  color: #7ec6ef;
}

.primary_modal .rdt_TableRow {
  height: unset;
  min-height: 40px;
}

.primary_modal .rdt_TableCell {
  font-size: 16px;
  font-weight: 300;
}

.custom_hr {
  border-color: #546280;
}

.input-group button {
  background: rgb(50 68 90);
  color: #fff;
}

/* .account_details_box .profile_inp_row:last-child {
  margin-bottom: 0;
} */

.cur_pointer {
  cursor: pointer;
}

.aircraft_box {
  border-radius: 16px;
  border: 1px solid #1e232e;
  background: #0e1116;
}

.aircraft_box_head {
  border-radius: 16px 16px 0px 0px;
  background: #1e232e;
  padding: 8px 0px;
}

.aircraft_box_head h4 {
  font-size: 14px;
  text-align: center;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.042px;
  margin-bottom: 0;
}

.aircraft_box_body {
  display: flex;
  align-items: center;
  padding: 16px;
}

.aircraft_box_body_left,
.aircraft_box_body_right {
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.aircraft_box_body_left h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.054px;
  margin: 5px 0;
}

.aircraft_box_body_left h5,
.aircraft_box_body_right h5 {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.042px;
  margin: 0;
}

.aircraft_box_footer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  padding: 16px;
}

.aircraft_box_footer button.border_btn {
  color: #7EC6EF;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
}

.aircraft_details_top {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.aircraft_details_top_left,
.aircraft_details_top_right {
  width: 50%;
  padding: 30px;
}

.aircraft_details_top_left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-right: 1px solid #1E232E;
}

.aircraft_details_top_right p {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  display: flex;
  gap: 8px;
}

.aircraft_details_top_left h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0.054px;
  margin: 0;
}

.aircraft_details_top_left h5 {
  color: #868686;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.042px;
  margin: 0;
}

.aircraft_details_top_right {
  padding-left: 80px;
}

.escrow_chart_info ul li::before {
  background: #2B313D;
}

.escrow_chart_info ul li:nth-child(2):before {
  background: #83FAE3;
}

.escrow_chart_info ul li:nth-child(3):before {
  background: #FF84C7;
}

.escrow_chart_info ul li:nth-child(4):before {
  background: #FFE484;
}

.trans_disabled .activity_dot {
  background: rgba(255, 255, 255, 0.50);
  ;
}

.trans_disabled .activity_content h2 {
  color: rgba(255, 255, 255, 0.50);
}

.trans_checkpoints_panel .recent_activity_div {
  min-height: 70px;
  margin-right: 0;
}

.trans_checkpoints_panel .recent_activity_div:last-child {
  margin-bottom: 0;
}

.aircraft_details_bottom {
  display: flex;
  align-items: flex-start;
}

.aircraft_details_bottom_left,
.aircraft_details_bottom_right {
  width: 50%;
}

.aircraft_details_bottom_left {
  border-right: 1px solid #1E232E;
}

.aircraft_details_row {
  display: flex;
  gap: 8px;
  margin-bottom: 5px;
}

.aircraft_details_bottom_left label,
.aircraft_details_bottom_right label {
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  width: 50%;
  text-align: right;
}

.aircraft_details_bottom_left label:nth-child(2),
.aircraft_details_bottom_right label:nth-child(2) {
  color: #fff;
  width: 50%;
  text-align: left;
}

.broker_info {
  width: 50%;
  margin-left: auto;
  margin-bottom: 10px;
}

.broker_info p {
  font-size: 12px;
  margin-bottom: 5px;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 4px;
}

.aircraft_details_row .accordion-button::after {
  content: "";
  background: url("./assets/images/dropdown_icon.png");
  width: 27px;
  height: 18px;
  margin-left: unset;
}

.aircraft_details_row .accordion-button:not(.collapsed)::after {
  transform: rotate(180deg);
}

.form_subhead {
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.048px;
  margin: 16px 0 8px;
}

.pos_rel {
  position: relative;
}

.primary_input[type=file] {
  position: absolute;
  top: 0;
  opacity: 0;
}

form button.border_btn,
form button.primary_btn {
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: normal;
}

.support_note {
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.464px;
}

.support_accordion .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  background: #13181F;
  border-color: #13181F;
}

.support_accordion .accordion-button {
  border-radius: 10px !important;
  background: #13181F;
  box-shadow: none !important;
}

.support_accordion .accordion-button::after {
  filter: invert(1);
  background-size: 80%;
}

.support_accordion .accordion-button:not(.collapsed)::after {
  filter: unset;
}

.support_head {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.supportissue_type {
  margin-bottom: 0;
  color: #7ec6ef;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.supportissue_type span {
  color: rgba(255, 255, 255, 0.50);
}

.supportissue_details {
  display: flex;
  gap: 24px;
  margin-right: 30px;
}

.chat_wrapper {
  min-height: 400px;
  overflow-x: auto;
  max-height: 400px;
  margin-bottom: 10px;
}

.chat_single {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  margin-bottom: 12px;
}

.chat_single img {
  border-radius: 10px;
  border: 1px solid #1E232E;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.25);
  width: 50px;
  height: 50px;
}

.chat_msg {
  width: 70%;
  background: rgb(126 198 239 / 20%);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
  font-size: 14px;
}

.chat_msg p {
  margin-bottom: 5px;
}

.chat_msg span {
  color: rgba(255, 255, 255, 0.50);
  font-weight: 300;
}

.chat_single_admin .chat_msg {
  margin-left: auto;
  background: rgb(51 58 71 / 20%);
}

.chat_single.chat_single_admin {
  margin-right: 10px;
}

button.msg_attach {
  position: relative;
}

button.msg_attach input[type=file] {
  position: absolute;
  opacity: 0;
  width: 64px;
  height: 100%;
}

.notification_div {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 10px;
}

.notification_div .recent_activity_div {
  margin: 0;
  width: 100%;
}

button.notify_delete {
  display: flex;
  width: 76px;
  height: 76px;
  padding: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #13181F;
}

.cms_nav.nav-pills .nav-link {
  color: #FFF;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  background: transparent;
  border-radius: 0;
}

.cms_nav.nav-pills .nav-link.active {
  color: #7EC6EF;
  font-weight: 700;
  border-bottom: 2px solid #7EC6EF;
}

.cms_content {
  margin-top: 16px;
  min-height: 80vh;
}

.cms_content p {
  color: #FFF;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  margin-bottom: 30px;
}

.faq_accordion .accordion-button {
  border-radius: 10px !important;
  background: #13181F;
  box-shadow: none !important;
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  color: #fff;
}

.faq_accordion .accordion-item {
  margin-bottom: 10px;
  border-radius: 10px !important;
  background: #13181F;
  border-color: #13181F;
}

.faq_accordion .accordion-body {
  padding-top: 0;
}

.faq_accordion .accordion-body p {
  color: rgba(255, 246, 234, 0.50);
  font-weight: 300;
  font-size: 14px;
  margin-bottom: 0;
  line-height: 24px;
}

.faq_accordion .accordion-button::after {
  filter: invert(1);
}

.faq_accordion .accordion-button:not(.collapsed)::after {
  filter: unset;
}

.audit_nav {
  gap: 4px;
  border-bottom: 1px solid #000;
  padding: 10px 0 15px;
  margin-bottom: 30px;
  position: sticky;
  top: 80px;
  background: #0e1116;
  flex-wrap: nowrap;
  overflow-x: auto;
}

.audit_nav .nav-item {
  flex: unset;
}

.audit_nav .nav-item a {
  width: 40px;
  height: 50px;
  background: #13181F;
  border: 1px solid #13181F;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  border-radius: 10px;
  cursor: pointer;
}

.audit_nav .nav-item a:hover {
  border: 1px solid #1E232E;
}

.audit_inner_div {
  display: grid;
  gap: 15px;
  grid-template-columns: 210px repeat(auto-fill, 210px);
  border-bottom: 1px solid #000;
  padding-bottom: 10px;
}

.audit_inner_div a {
  border-radius: 10px;
  background: #13181F;
  display: flex;
  padding: 8px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #CAD8EA;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.464px;
  margin-bottom: 0;
  height: 50px;
}

.audit_div_wrap h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.audit_div_wrap {
  margin-top: 10px;
}

button.download_btn {
  display: flex;
  padding: 4px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid #1E232E;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
  color: #fff;
}

.audit_detail_top {
  display: flex;
  align-items: center;
}

.audit_detail_top_left,
.audit_detail_top_right {
  width: 50%;
}

.audit_detail_top_left {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.audit_detail_top_left p,
.audit_detail_top_right p {
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  display: flex;
  align-items: center;
  gap: 5px;
  white-space: nowrap;
}

.audit_detail_middle {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
}

.audit_detail_middle_left,
.audit_detail_middle_right {
  width: 50%;
  border-radius: 8px;
  border: 1px solid #1E232E;
  padding: 16px;
}

.audit_detail_middle h3 {
  font-size: 18px;
  font-weight: 500;
  line-height: normal;
}

.audit_detail_middle ul {
  list-style: none;
}

.audit_detail_middle ul li {
  font-size: 16px;
  font-weight: 300;
  line-height: normal;
  margin-bottom: 8px;
}

.audit_detail_middle ul li span {
  font-weight: 600;
}

.audit_detail_bottom {
  margin-top: 24px;
  border-radius: 8px;
  border: 1px solid #1E232E;
  padding: 16px;
}

.audit_detail_bottom h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
}

.audit_detail_bottom h3 span {
  font-weight: 500;
}

.audit_detail_bottom p {
  color: rgba(255, 255, 255, 0.50);
  font-size: 14px;
  font-weight: 200;
  line-height: normal;
  padding-left: 32px;
  margin-bottom: 0;
}

.audit_detail_bottom h5 {
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  line-height: normal;
  margin: 15px 0 10px;
}

.address_wrap {
  white-space: normal;
  word-break: break-all;
}

.low_risk {
  color: #65FF99
}

.medium_risk {
  color: #FFF965
}

.high_risk {
  color: #E34A4A
}


.form-control:-webkit-autofill { 
  -webkit-text-fill-color: white;
  background: black!important;
  -webkit-box-shadow: 0 0 0px 1000px #000 inset;
  transition: background-color 5000s ease-in-out 0s;
  caret-color: white;
}

.tabheads{
  cursor: pointer;
  border-bottom: 2px solid transparent;
}
.tabheads.active{
  color: #7EC6EF; 
  border-bottom: 2px solid #7EC6EF;
}
.quote_status_report .col-12 .row div.col-12:nth-child(2) {
  color: #7ec6ef;
}
.address_val {
  white-space: normal !important;
  word-break: break-all;
  padding: 5px 0;
}

.custom_tooltip .apexcharts-tooltip-marker,
.custom_tooltip .apexcharts-tooltip-text-y-label{
  display: none;
}

.form-control::-ms-input-placeholder { /* Edge 12-18 */
  color: #55575c !important;
}

.form-control::placeholder {
  color: #55575c !important;
}
.search_input_div svg{
  color: #55575c !important;
  fill: #55575c !important;
  filter: contrast(0) brightness(1);
}
.home-works-group{
  display: flex;
  gap:30px;
  justify-content: space-between;
}
.hf-card{
  width: 156px;
  min-height: 152px;
  padding: 12px;
}
.hf-card img{
  margin-bottom: 12px;
}

.mobilehome-transaction{
  max-width: 90%;
}
.mobilehome-transaction{
  width: 90%;
  max-width: 760px;
  margin: 0 auto;
  padding: 50px;
}
.mobile-fp-space{
  padding: 30px !important;
}
.footer-main{
  width: 90%;
  max-width: 924px;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding: 24px 0;
}
/* Media Queries */
@media screen and (max-width: 1399px) {
  .total_fund_value h3 {
    font-size: 24px;
  }

  .total_fund_value {
    gap: 24px;
  }
}

@media screen and (max-width: 1279px) {
  .hrs_chart_flex {
    flex-direction: column;
  }

  .total_fund_value h3 {
    font-size: 18px;
  }

  .wallet_panel {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media screen and (max-width: 1199px) {
  .topnavbar_right i.fa-bars {
    display: block;
    font-size: 20px;
  }

  .app_sidebar {
    /* left: -250px;
    transition: left 0.3s ease; */
    display: none;
  }

  .page_wrapper {
    margin-left: 0;
  }

  .app_header {
    left: 0;
  }

  .app_sidebar.show {
    left: 0;
  }

  body.menu_overlay {
    overflow: hidden;
    position: relative;
  }

  body.menu_overlay::before {
    content: '';
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 101;
  }

  .app_sidebar i.close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 24px;
    display: block;
  }

  .offcanvas {
    background: #0e1116;
  }

  .auth_left {
    width: 70%;
  }

  .app_header .offcanvas-body .navbar-nav {
    align-items: flex-start;
    margin-top: 24px;
  }
  .page_wrapper{
    margin-top: 140px;
  }
}

@media screen and (max-width: 991px) {
  .top_filter_wrapper {
    flex-direction: column;
    gap: 15px;
    align-items: flex-start;
  }

  .top_filter {
    flex-wrap: wrap;
  }

  .auth_wrapper::before {
    display: none;
  }

  .auth_left {
    width: 100%;
  }

  .sendquote_div {
    flex-direction: column;
  }

  .sendquote_div_left,
  .sendquote_div_right {
    width: 100%;
  }

  .dashtitle_flex {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .audit_detail_top_left,
  .audit_detail_top_right,
  .audit_detail_middle_left,
  .audit_detail_middle_right {
    width: 100%;
  }

  .audit_detail_top,
  .audit_detail_middle {
    flex-direction: column;
  }

  .audit_detail_top_left p,
  .audit_detail_top_right p {
    justify-content: center;
  }
  .home-works-group{
    flex-wrap: wrap;
    justify-content: center;
  }
}

@media screen and (max-width: 767px) {
  .auth_left h2 {
    font-size: 32px;
  }

  .total_fund_value {
    flex-wrap: wrap;
    margin-top: 30px;
  }

  .table_filter_options {
    flex-wrap: wrap;
  }

  .account_details_panel {
    flex-direction: column;
  }

  .custom_vertical_tab {
    flex-direction: row !important;
    flex-wrap: nowrap;
    white-space: nowrap;
    overflow-x: auto;
    padding-bottom: 10px;
  }

  .account_details_left {
    padding: 24px 24px 0;
  }

  .aircraft_box_body {
    flex-direction: column;
    row-gap: 12px;
  }

  .aircraft_box_body_left,
  .aircraft_box_body_right {
    width: 100%;
  }

  .aircraft_details_top {
    flex-direction: column;
    align-items: center;
  }

  .aircraft_details_top_left,
  .aircraft_details_top_right {
    padding: 10px;
  }

  .aircraft_details_top_left,
  .aircraft_details_bottom_left {
    border-right: 0;
  }

  .aircraft_details_top_right p {
    justify-content: center;
  }

  .aircraft_details_bottom {
    flex-direction: column;
  }

  .aircraft_details_bottom_left,
  .aircraft_details_bottom_right,
  .aircraft_details_top_left,
  .aircraft_details_top_right {
    width: 100%;
  }

  .chat_msg {
    width: 100%;
  }

  .chat_single {
    margin-right: 10px;
  }

  .support_head {
    flex-direction: column;
    gap: 10px;
  }
  .quote_status_report .col-12 .row div.col-12:nth-child(2).justify-content-end {
    justify-content: flex-start !important;
  } 
  .quote_status_report .ps-copy-btn {
    text-align: left;
  }
  .tabTitle h2.dash_inner_title{
    width: 100%;
  }
  .mobil-heading{
    font-size: 42px;
  }
}

@media screen and (max-width: 575px) {
  .usericon_dropdown span {
    display: none;
  }

  h2.page_title {
    font-size: 20px;
  }

  .topnavbar_right {
    gap: 15px;
  }

  .topnavbar_right .dropdown-toggle::after {
    margin-left: 10px;
  }

  .page_content .container-fluid,
  .app_header .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .top_filter .MuiFormControl-root,
  .top_filter_wrapper .MuiButton-root {
    width: 100%;
  }

  .auth_left h2 {
    font-size: 24px;
  }

  .auth_footer {
    flex-direction: column;
    align-items: unset;
  }

  .auth_left {
    padding: 30px 20px;
  }

  .auth_footer a {
    text-align: center;
    margin-top: 30px;
  }

  .otp_wrap input.form-control {
    padding: 8px 5px;
  }

  .link_box_panel {
    gap: 12px;
  }

  .traffic_chart_flex {
    flex-direction: column;
  }

  .asset_chart_info {
    width: 100%;
  }

  .wallet_panel {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .profile_top {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .profile_data,
  .profile_img {
    margin-top: 0;
  }

  .profile_inp_row {
    flex-direction: column;
    align-items: flex-start !important;
    gap: 2px;
    margin-bottom: 20px;
  }

  .profile_inp {
    padding-left: 0;
  }

  .security_list li {
    flex-wrap: wrap;
  }

  h2.dash_inner_title {
    font-size: 16px;
  }

  .aircraft_box_footer {
    flex-direction: column;
  }

  .aircraft_box_footer button.border_btn {
    font-size: 14px;
  }

  .supportissue_details {
    flex-direction: column;
    gap: 10px;
  }

  .chat_single {
    flex-direction: column;
  }

  .chat_single.chat_single_admin {
    flex-direction: column-reverse;
  }

  button.notify_delete img {
    width: 20px;
  }

  .notification_div {
    gap: 10px;
  }

  button.notify_delete {
    width: 50px;
    height: 40px;
    padding: 5px;
    min-width: unset;
  }

  .audit_inner_div {
    grid-template-columns: repeat(1, minmax(0, 1fr))
  }

  .audit_detail_top_left p,
  .audit_detail_top_right p {
    font-size: 14px;
  }

  .audit_detail_middle ul {
    padding-left: 0;
  }

  .audit_detail_middle ul li {
    font-size: 14px;
  }
  .activity_content p{
    text-align: left;
  }
  .btn-group_custom{
    margin: 0 auto;
  }
  .hf-card{
    width: 90%;
  }
  .mobilespace-fb {
    padding: 0 30px;
  }
  .mobile-fp-space {
    padding: 0;
  }
  .footer-main{
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .footer-main nav:first-child{
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
  }
}

.verify-container {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.verify-container>div {
  display: flex;
  flex-direction: column;
  gap: 1em;
  align-items: center;
  padding: 1em;
  text-align: center;
}

.verify-container>div>a {
  padding: 10px 20px;
  border: #7ec6ef 1px solid;
  border-radius: 5px;
}

.verify-container>div>p {
  font-size: 1.5em;
  margin-bottom: 0.1em !important;
}

.logout-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  gap: 1em;
}

.reply_image {
  width: 33% !important;
  height: calc(33%*4) !important;
}

.reply_label {
  width: 100%;
  color: white !important;
  background-color: rgb(126 198 239 / 20%) !important;
  padding: 7px 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.reply_label>p {
  margin-bottom: 0 !important;
}

.reply_label>i {
  margin-right: 1em;
}

.border_red {
  border-color: #e34a4a !important;
}

.ps-contain {
  /* width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center; */
  padding: 5vh 0;
}

.ps-copy-btn {
  color: #7ec6ef !important;
  padding: 0 !important;
  margin: 0 !important;
  word-break: break-word;
  background-color: transparent !important;
  border: 0px solid transparent !important;
  text-align: right;
}

.ps-pad-right {
  padding-right: calc(var(--bs-gutter-x) * .5) !important;
}

.datepicker_hidden {
  display: none !important;
  visibility: hidden;
}

.txn-custom-active {
  background-color: #7EC6EF !important;
  color: #000 !important;
}

.pass-container {
  position: relative;
}

.pass-visibler {
  position: absolute;
  left: 97.5%;
  top: -13px;
  transform: translate(-100%, 100%);
  z-index: 1;
  color: white;
  font-size: 1.25em;
  cursor: pointer;
  padding: 10px;
}

.change-pass-visibler {
  position: absolute;
  right: 0%;
  top: 80%;
  transform: translate(-50%, -100%);
  font-size: 1.25em;
}

.pencil_icon_position {
  position: absolute;
  transform: translate(-100%, -50%);
}

.no_faq_wrapper {
  width: 100% !important;
  height: 80vh !important;
  display: flex;
  justify-content: center;
  align-items: center;
}

.resend_btn {
  border: 0.5px solid #7EC6EF;
  background-color: #7EC6EF;
  color: #000;
  border-radius: 5px;
  padding: 9px 20px !important;
  width: max-content !important;
  font-weight: 400 !important;
  position: absolute;
  right: 0;
  transform: translate(-10%, -120%);
}

.really-small-font {
  font-size: 0.65em;
}

.hideit-from {
  display: none;
}

.react-datepicker__triangle::after, .react-datepicker__triangle::before{
  display: none !important;
}

.contact-modal-header {
  background-color: #1e232e;
  color: white;
}